@import '../../assets/scss/variable.scss';

.form-notification {
    margin: 1.5rem 0.5rem;

    .card {
        border: 1px solid $border_color;

        .card-body {
            flex: 1 1 auto;
            min-height: 1px;
            padding: 1.5rem;

            .error-message {
                color: #ea4c4cd9 !important;
                font-size: 0.785rem;
                margin-top: 0.3rem;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            .error-img-message {
                color: #ea4c4cd9 !important;
                font-size: 0.785rem;
                margin-top: -0.60rem;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            hr {
                opacity: 0.2;
            }

            label {
                color: #525f7f;
                font-size: 0.875rem;
                font-weight: 600;
                display: inline-block;
                margin-bottom: 0.5rem;
            }

            .form-control-alternative {
                border: 1px solid #ccc;
                transition: box-shadow 0.15s ease;
                font-size: 0.875rem;
                color: #626262;
                padding: 0.625rem 0.75rem;
                height: calc(1.5em + 1.25rem + 2px);

                &::placeholder {
                    color: #8898aa;
                    font-size: 0.82rem;
                    font-weight: normal;
                }
            }

            textarea.form-control {
                height: auto;
            }

            .file-card {
                display: flex;
                align-items: center;

                .file-inputs {
                    position: relative;
                }

                .file-upload {
                    position: relative;
                    max-width: 106px;
                    height: 36px;
                    z-index: 2;
                    cursor: pointer;
                    opacity: 0;
                }

                .file-btn {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $white_color;
                    background-color: $main_color;
                    font-size: 0.90rem;
                    cursor: pointer;
                    border-radius: 4px;
                    border: none;
                    outline: none;
                    transition: background-color 0.4s;
                    box-shadow: none;
                }

                .file-svg {
                    width: 1.5em;
                    height: 1.5em;
                    padding: 0.4em;
                    background-color: $white_color;
                    color: rgb(85, 84, 84);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0.8em;
                    font-size: 0.9rem;
                }
            }

            .file-list {
                padding-left: 0;
                padding-top: 0.9rem;

                .list-item {
                    width: 7rem;
                    height: 8rem;
                    list-style: none;
                    background-color: #ebebeb;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    padding: 0.8rem;
                    position: relative;

                    .file-img {
                        width: 5rem;
                        height: 6rem;
                    }

                    .actions {
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }

        .card-footer {
            padding: 0 1.5rem 1.25rem 1.5rem;
            margin-bottom: 0;

            .form-button {
                display: flex;
                gap: 10px;

                .btn {
                    font-size: 0.90rem;
                    position: relative;
                    text-transform: none;
                    transition: all 0.15s ease;
                    letter-spacing: 0.025em;
                    will-change: transform;
                    padding: 0.30rem 0.55rem;
                    line-height: 1.5;
                    border-radius: 0.375rem;
                    color: $white_color;
                    background-color: $main_color;
                    border-color: $main_color;
                    box-shadow: none;
                }
            }
        }
    }
}