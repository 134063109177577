@import '../../assets/scss/variable.scss';

.history {
    margin: 1.5rem 0.5rem;

    .card {
        border: 1px solid $border_color;

        .card-header {
            padding: 1rem;
            margin-bottom: 0;
            background-color: $white_color;

            &:first-child {
                border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
            }

            .header-btn {
                display: flex;
                gap: 10px;

                .btn {
                    font-size: 0.90rem;
                    position: relative;
                    text-transform: none;
                    transition: all 0.15s ease;
                    letter-spacing: 0.025em;
                    will-change: transform;
                    padding: 0.30rem 0.55rem;
                    line-height: 1.5;
                    border-radius: 0.375rem;
                    color: $white_color;
                    background-color: $main_color;
                    border-color: $main_color;
                    box-shadow: none;
                }

                .filter {
                    font-size: 0.90rem;
                    position: relative;
                    text-transform: none;
                    transition: all 0.15s ease;
                    letter-spacing: 0.025em;
                    will-change: transform;
                    padding: 0.30rem 0.55rem;
                    line-height: 1.5;
                    border-radius: 0.375rem;
                    color: $white_color;
                    background-color: #3a3434;
                    border-color: #3a3434;
                    box-shadow: none;
                }
            }
        }
    }


    .table-responsive {
        display: block;
        width: 100%;
        min-height: 400px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        table {
            margin-bottom: 0;
            color: $table_font_color;
        }

        thead {
            background-color: #f6f6f6;
        }

        th:first-child {
            padding-left: 1rem;
            width: 70px;
        }

        td:first-child {
            padding-left: 1rem;
        }

        th:last-child {
            padding-right: 1rem;
            width: 70px;
        }

        th:nth-last-child(6) {
            width: 150px;
        }

        th:nth-last-child(5) {
            width: 100px;
        }

        th:nth-last-child(2) {
            width: 100px;
        }

        td:last-child {
            padding-right: 1rem;
        }

        td:nth-last-child(6) {
            width: 150px;
        }

        td:nth-last-child(5) {
            width: 100px;
        }

        td:nth-last-child(2) {
            width: 100px;
        }

        th {
            padding: 0.5rem;
            color: #000;
            vertical-align: middle;
            font-size: 0.65rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-bottom: 1px solid $table_border;
            border-left: 0;
            border-right: 0;
            white-space: nowrap;
            font-weight: bold;
            border-top: 1px solid $table_border;
        }

        .text-right {
            text-align: right !important;
        }

        td {
            border-top: 0;
            vertical-align: middle;
            border-left: 0;
            border-right: 0;
            font-size: $table_font_size;
            white-space: nowrap;
            padding: 0.5rem;
            font-weight: 500;

            .dropdown {
                .btn-icon-only.btn-sm {
                    width: 2rem;
                    height: 2rem;
                }

                .btn-icon-only {
                    padding: 0;

                    i {
                        pointer-events: none;
                    }
                }

                .btn {
                    border: 0;
                    color: $table_font_color !important;

                    &:hover {
                        box-shadow: $shadow;
                        transform: translateY(-1px);
                    }

                    &:focus {
                        outline: none;
                        box-shadow: $shadow;
                    }
                }

                .dropdown-menu {
                    z-index: 1000;
                    float: left;
                    padding: 0.5rem 0;
                    margin: 0.125rem 0 0;
                    font-size: 1rem;
                    color: #525f7f;
                    text-align: left;
                    list-style: none;
                    background-color: $white_color;
                    background-clip: padding-box;
                    border: 0 solid rgba(0, 0, 0, 0.15);
                    border-radius: 0.4375rem;
                    box-shadow: 0 50px 100px rgba(51, 51, 95, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
                    min-width: 6rem;

                    .dropdown-item {
                        padding: 0.3rem 1rem;
                        color: #212529;
                        font-size: 0.85rem;

                        &:active {
                            background-color: $main_color;
                            color: $white_color;
                        }
                    }
                }
            }

            .avatar {
                color: $white_color;
                background-color: $gray_color;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                border-radius: 50% !important;
                height: 48px;
                width: 48px;

                img {
                    width: 100%;
                    border-radius: 50%;
                    vertical-align: middle;
                    border-style: none;
                    aspect-ratio: 1/1;
                }
            }
        }
    }

    h3 {
        font-size: 1.0625rem;
        margin-bottom: 0.5rem;
        font-family: inherit;
        font-weight: 600;
        line-height: 1.5;
        color: #32325d;
    }
}

.modal {
    &.show {
        .modal-dialog {
            &.file-form {
                .modal-content {
                    width: 100%;
                    padding: 0 0.50rem;

                    .modal-body {
                        color: $black_color;

                        label {
                            color: #525f7f;
                            font-size: 0.875rem;
                            font-weight: 600;
                            display: inline-block;
                            margin-bottom: 0.5rem;
                        }

                        .form-control-alternative {
                            border: 1px solid #ccc;
                            transition: box-shadow 0.15s ease;
                            font-size: 0.875rem;
                            color: #626262;
                            padding: 0.625rem 0.75rem;
                            height: calc(1.5em + 1.25rem + 2px);

                            &::placeholder {
                                color: #8898aa;
                                font-size: 0.82rem;
                                font-weight: normal;
                            }
                        }

                        .error-message {
                            color: #ea4c4cd9 !important;
                            font-size: 0.785rem;
                            margin-top: 0.3rem;
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }

                        .error-file-message {
                            color: #ea4c4cd9 !important;
                            font-size: 0.785rem;
                            margin-top: -0.5rem;
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }

                        .switch {
                            display: flex;
                            align-items: flex-start;
                            justify-content: left;
                            margin-left: -2.5rem;
                        }

                        .form-switch {
                            margin-top: 0.2rem;

                            .form-check-input {
                                margin: 0 0 0.75rem -2.5em;
                                width: 3em;
                                height: 1.5em;
                            }
                        }

                        .file-card {
                            display: flex;
                            align-items: center;

                            .file-inputs {
                                position: relative;
                            }

                            .file-upload {
                                position: relative;
                                max-width: 106px;
                                height: 36px;
                                z-index: 2;
                                cursor: pointer;
                                opacity: 0;
                            }

                            .file-btn {
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: $white_color;
                                background-color: $main_color;
                                font-size: 0.90rem;
                                cursor: pointer;
                                border-radius: 4px;
                                border: none;
                                outline: none;
                                transition: background-color 0.4s;
                                box-shadow: none;
                            }

                            .file-svg {
                                width: 1.5em;
                                height: 1.5em;
                                padding: 0.4em;
                                background-color: $white_color;
                                color: rgb(85, 84, 84);
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-right: 0.8em;
                                font-size: 0.9rem;
                            }
                        }

                        .file-list {
                            padding-left: 0;
                            padding-top: 0.9rem;
                            position: relative;

                            .audio-file {
                                list-style: none;
                                position: relative;
                                width: 18rem;

                                .progress {
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    width: 18rem;
                                    height: 13px;
                                    font-size: 0.60rem;
                                }

                                .list-item-audio {
                                    width: 18rem;
                                    height: 5rem;
                                    list-style: none;
                                    background-color: #ebebeb;
                                    border-radius: 5px;
                                    display: flex;
                                    align-items: center;
                                    padding: 0.8rem;
                                    padding-top: 0.2rem;

                                    audio {
                                        height: 2.85rem;
                                        width: 15rem;
                                    }

                                    .actions {
                                        cursor: pointer;
                                        position: absolute;
                                        top: 0.1rem;
                                        right: 0.1rem;

                                        i {
                                            font-size: 1.3rem !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .modal-footer {
                        margin-top: 0;
                        padding-top: 0;
                        border-top: none;

                        .primary-btn {
                            font-size: 0.90rem;
                            position: relative;
                            text-transform: none;
                            transition: all 0.15s ease;
                            letter-spacing: 0.025em;
                            will-change: transform;
                            line-height: 1.5;
                            border-radius: 0.375rem;
                            color: $white_color;
                            background-color: $main_color;
                            border-color: $main_color;
                            box-shadow: none;

                            &:hover {
                                color: $white_color;
                                background-color: $main_color;
                                border-color: $main_color;
                            }
                        }

                        .cancel-btn {
                            font-size: 0.90rem;
                            position: relative;
                            text-transform: none;
                            transition: all 0.15s ease;
                            letter-spacing: 0.025em;
                            will-change: transform;
                            line-height: 1.5;
                            border-radius: 0.375rem;
                            color: black;
                            background-color: transparent;
                            border: 1px solid #c4c4c4;
                            box-shadow: none;

                            &:hover {
                                color: black;
                                background-color: transparent;
                                border: 1px solid #c4c4c4;
                                box-shadow: none;
                            }

                            &:focus {
                                color: black;
                                background-color: transparent;
                                border: 1px solid #c4c4c4;
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }
}