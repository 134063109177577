@import '../../../assets/scss/variable.scss';

.admin-navbar {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: $white_color;
    display: flex;
    height: 4rem;
    justify-content: space-between;
    border-bottom: 1px solid $border_color;

    .sidebar_btn {
        margin: 1rem;
        display: flex;
        align-items: center;

        button {
            border: none !important;
            color: $main_color  !important;

            i {
                font-size: 1.5rem;
            }
        }

        h6 {
            margin-bottom: 0 !important;
            margin-left: 1rem;
            text-transform: uppercase;
            font-size: 0.9rem !important;
            font-weight: 500;
            color: $black_color;
        }
    }

    .admin {
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        gap: 10px;

        .avtar {
            color: $white_color;
            background-color: $gray_color;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            border-radius: 50%;
            height: 35px;
            width: 35px;

            img {
                width: 100%;
                border-radius: 50%;
            }
        }

        .dropdown {
            cursor: pointer;
            
            .show {
                top: 10px !important;
            }

            .dropdown-header {
                font-size: 0.625rem !important;
                margin-bottom: 0.5rem !important;
                font-family: inherit !important;
                font-weight: 600 !important;
                line-height: 1.5 !important;
                color: #32325d !important;
            }

            .dropdown-toggle {
                all: unset !important;
                font-size: 0.875rem !important;
                font-weight: 600 !important;
                color: $main_color !important;
                text-transform: capitalize !important;

                &::after {
                    all: unset !important;
                }
            }

            .dropdown-item {
                &:active {
                    a {
                        color: $white_color;
                        font-size: 0.9rem;
                        text-decoration: none;
                    }

                    background-color: $main_color;
                    color: $white_color;
                }
            }

            .inactive {
                color: $light_black;
                font-size: 0.9rem;
                text-decoration: none;

                &:hover {
                    color: $black_color;
                }

                &:active {
                    color: $white_color; 
                }
            }

            .active {
                font-size: 0.9rem;
                text-decoration: none;
                color: $main_color;
            }

            span {
                margin-left: 1rem;
            }

            i {
                min-width: 2.25rem;
                font-size: 0.9375rem;
                line-height: 1.5rem;
            }
        }


    }
}

@media only screen and (max-width: 350px) {
    .admin-navbar {
        .sidebar_btn {
            margin: 0;

            h6 {
                margin-left: 0.3rem;
            }
        }

        .admin {
            padding: 1rem;
        }
    }
}