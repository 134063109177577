@import '../../assets/scss/variable.scss';

.login-as {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    .login-as-form {
        text-align: center;
        position: relative;
        width: 20rem;

        .error-message {
            color: #ed4949;
            font-size: 0.785rem;
            margin-top: -0.75rem;
            margin-bottom: 1rem;
            display: flex;
        }

        input,
        button {
            width: 100%;
            border: 0;
            border-radius: 0.50rem;
        }

        input {
            border-bottom: 2px solid #8e97a1;
            padding: 0.75rem 2.5rem 0.75rem 1.25rem;
            background-color: #f7fafc;

            &::placeholder {
                color: #8e97a1;
            }

            &:focus,
            &:focus::placeholder,
            &:focus+i {
                color: $main_color;
            }

            &:focus {
                outline: 0;
            }
        }

        input,
        button,
        .group i,
        a {
            font-size: 0.833rem;
            font-weight: 600;
        }

        .group {
            margin-bottom: 1rem;
            position: relative;

            i {
                position: absolute;
                top: 0.938rem;
                right: 1.25rem;
                color: #8e97a1;
            }
        }

        button {
            padding: 0.75rem;
            background: linear-gradient(45deg, #c5f1f6 0%, $main_color 100%);
            margin-bottom: 1.25rem;
            cursor: pointer;
            color: #fff;

            &:focus {
                outline: 0;
            }
        }
    }

    &:before,
    &:after {
        content: "";
        position: fixed;
        height: 18.75rem;
        width: 31.25rem;
    }

    &:before {
        background: linear-gradient(45deg, #c5f1f6 0%, $main_color 100%);
        left: 0;
        top: 0;
        clip-path: polygon(0 0, 0 100%, 100% 0);
    }

    &:after {
        background: linear-gradient(45deg, $main_color 0%, #c5f1f6 100%);
        right: 0;
        bottom: 0;
        clip-path: polygon(100% 0, 0 100%, 100% 100%);
    }
}

@media (max-width: 767px) {

    .login-as:before,
    .login-as:after {
        height: 9.375rem;
        width: 18.75rem;
    }
}

@media (max-width: 345px) {
    .login-as {
        .login-as-form {

            input,
            button {
                width: 90%;
            }
        }
    }
}