@import '../../assets/scss/variable.scss';

.header {
    position: relative;
    margin: 1.5rem 1.3rem;
    padding: 1.5rem 0;

    .card-stats {
        margin: 1rem !important;

        a {
            text-decoration: none;
            color: #212529;
        }

        .card-body {
            padding: 1.5rem;
            cursor: pointer;
        }
    }

    h5 {
        font-size: 0.8125rem;
        font-weight: 600;
    }

    span {
        font-size: 1.1rem;
        font-weight: bold;
    }

    .icon-shape {
        padding: 0.75rem;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 1.25rem;
        }
    }

    .text-white {
        color: $white_color !important;
    }

    .icon {
        width: 3rem;
        height: 3rem;
    }
}

.bg-gradient-info {
    background: linear-gradient(87deg, #e9e9e9 0, #cdd3d3 100%) !important;
    border-radius: 0.3rem;
}

@media only screen and (max-width: 325px) {
    .header {
        h5 {
            font-size: 0.50rem;
        }

        span {
            font-size: 0.95rem;
        }

        .icon-shape {
            i {
                font-size: 1rem;
            }
        }

        .icon {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}