@import '../../../assets/scss/variable.scss';

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 15rem;
    height: 100vh;
    align-items: center;
    padding: 1rem 0rem;
    background-color: $sidebar_color;
    transform: translateX(0%);
    transition: transform 0.4s ease-in;
    border-right: 1px solid $border_color;

    .scrollar-hide::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &.hide {
        transform: translateX(-100%);
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 1rem;
    }

    img {
        width: 150px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: calc(100vh - 5rem);
        overflow-y: auto;
    }

    li {
        display: flex;
        align-items: center;
        padding: 0.65rem 1.8rem;
        cursor: pointer;
        position: relative;

        .sidebar-item {
            color: rgba(38, 37, 37, 0.66);
            font-size: 0.9rem;
            width: 100%;
            padding: 0.20rem 0;

            a {
                text-decoration: none;
            }

            .active-child {
                .sidebar-title {
                    font-size: 0.9rem;
                    color: $black_color;
                }
            }

            .sidebar-content {
                display: none;
            }

            .sidebar-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                color: rgba(38, 37, 37, 0.66);

                span {
                    display: flex;

                    i {
                        display: inline-block;
                        width: 25px;
                    }
                }

                .bi-caret-down-fill {
                    cursor: pointer;
                    transition: transform 0.15s;
                    margin-left: auto;
                    color: #000000ba;
                }

                &:hover {
                    color: $black_color;
                }
            }

            &.drop-open {
                .sidebar-title {
                    &>.bi-caret-down-fill {
                        transform: translateY(-3px) rotate(180deg);
                    }
                }

                .sidebar-content {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                }
            }

            &.is-open-active {
                font-size: 0.9rem;
                color: $black_color;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0.35rem;
                    bottom: 7.7rem;
                    border-left: 5px solid $main_color;
                }

                .sidebar-title {
                    &.main-color {
                        color: $black_color;
                    }
                }
            }

            &.is-close-active {
                font-size: 0.9rem;
                color: $black_color;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0.35rem;
                    bottom: 0.55rem;
                    border-left: 5px solid $main_color;
                }

                .sidebar-title {
                    &.main-color {
                        color: $black_color;
                    }
                }
            }

            .sidebar-content {
                padding-top: 10px;
                height: 0;
                overflow: hidden;
                transition: all 0.15s;

                &>i {
                    width: 25px;
                    font-size: 14px;
                }
            }
        }
    }

    .text-default {
        color: #172b4d !important;
    }

    .inactive {
        color: rgba(38, 37, 37, 0.66);
        font-size: 0.9rem;
        text-decoration: none;

        &:hover {
            color: $black_color;
        }
    }

    .active {
        font-size: 0.9rem;
        text-decoration: none;
        color: $black_color;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0.25rem;
            bottom: 0.25rem;
            border-left: 5px solid $main_color;
        }
    }

    span {
        margin-left: 2rem;
    }
}

.sidebar-overlay {
    position: fixed;
    height: 100vh;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    left: 0rem;
    background: rgba($color: #555555, $alpha: 0.5);
    z-index: 9;
    display: none;
}

@media only screen and (max-width: 768px) {
    .sidebar-overlay {
        &.show {
            display: block;
        }
    }
}