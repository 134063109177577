@import '../../assets/scss/variable.scss';

.profile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .main-background {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background: linear-gradient(45deg, #c5f1f6 0%, #7687eb 100%);
        width: 100%;
        height: 45vh;
    }

    .background-block {
        position: absolute;
        top: 20rem;
        background: #f7fbfd;
        box-shadow: 0 16px 24px 2px rgb(0 0 0 / 0%), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
        border-radius: 10px;
        width: 70%;
        height: 40vh;
    }

    .profile-avatar {
        .avatar {
            position: relative;
            top: 11rem;
            color: #fff;
            background: linear-gradient(45deg, #7687eb 0%, #c5f1f6 100%);
            font-size: 1rem;
            border-radius: 50%;
            height: 275px;
            width: 275px;

            img {
                position: absolute;
                top: 9.5rem;
                left: 0.8rem;
                width: 90%;
                object-fit: cover;
                transform: translate3d(0, -50%, 0);
            }
        }

        .name {
            position: relative;
            top: 12rem;

            h3 {
                color: #3C4858;
                font-weight: 700;
                font-size: 1.75rem;
                text-align: center;
                text-transform: capitalize
            }

            h6 {
                text-align: center;
                color: rgba(38, 37, 37, 0.66);
                font-weight: 500;
                font-size: 0.80rem;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .profile {
        .background-block {
            top: 15rem;
        }

        .profile-avatar {
            .avatar {
                top: 6rem;

                img {
                    top: 9.5rem;
                }
            }

            .name {
                top: 7rem;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .profile {
        .background-block {
            top: 12rem;
        }

        .profile-avatar {
            .avatar {
                top: 4rem;

                img {
                    top: 9.5rem;
                }
            }

            .name {
                top: 4.8rem;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .profile {
        .background-block {
            top: 20rem;
        }

        .profile-avatar {
            .avatar {
                top: 12.5rem;
                height: 200px;
                width: 200px;

                img {
                    top: 8rem;
                }
            }

            .name {
                top: 14rem;
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    .profile {
        .main-background {
            height: calc(100vh - 4rem);

        }

        .background-block {
            top: 16rem;
            width: 90%;
            height: 40vh;
        }

        .profile-avatar {
            .avatar {
                top: 9rem;
                height: 180px;
                width: 180px;

                img {
                    top: 7.2rem;
                }
            }

            .name {
                top: 10rem;

                h3 {
                    font-size: 1.5rem;
                }
            }
        }
    }
}