@import '../../../assets/scss/variable.scss';

.footer {
    font-size: 0.875rem;
    color: #8898aa !important;
    padding: 0 0 1.3rem 1.3rem;

    a {
        color: $main_color;
        text-decoration: none;
        background-color: transparent;
        font-weight: 600 !important;
        margin-left: 0.25rem !important;
    }
}