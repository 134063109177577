$sidebar_color: #ffffff;
$border_color: #dddddd;
$main_color: #5e72e4;
$black_color: black;
$light_black: rgba(38, 37, 37, 0.5);
$white_color: #fff;
$gray_color: #ebebeb;
$table_border: #e9ecef;
$table_font_color: #525f7f;
$table_font_size: 0.75rem;
$shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
$view-font: 0.8rem;