@font-face {
    font-family: 'font';
    src: url('../fonts/OpenSans-SemiBold.ttf');
}

* {
    font-family: 'font';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

.layout {
    width: 100%;
    padding-left: 15rem;
    transform: translateX(-0%);
    transition: padding-left 0.4s ease-in, width 0.4s ease-in;

    &.hide {
        padding-left: 0;
        width: 100%;
    }

    .primary-btn {
        font-size: 0.90rem;
        position: relative;
        text-transform: none;
        transition: all 0.15s ease;
        letter-spacing: 0.025em;
        will-change: transform;
        line-height: 1.5;
        border-radius: 0.375rem;
        color: $white_color;
        background-color: $main_color;
        border-color: $main_color;
        box-shadow: none;

        &:hover {
            color: $white_color;
            background-color: $main_color;
            border-color: $main_color;
            box-shadow: none;
        }

        &:focus {
            color: $white_color;
            background-color: $main_color;
            border-color: $main_color;
            box-shadow: none;
        }
    }

    .cancel-btn {
        font-size: 0.90rem;
        position: relative;
        text-transform: none;
        transition: all 0.15s ease;
        letter-spacing: 0.025em;
        will-change: transform;
        line-height: 1.5;
        border-radius: 0.375rem;
        color: black;
        background-color: transparent;
        border: 1px solid #c4c4c4;
        box-shadow: none;

        &:hover {
            color: black;
            background-color: transparent;
            border: 1px solid #c4c4c4;
            box-shadow: none;
        }

        &:focus {
            color: black;
            background-color: transparent;
            border: 1px solid #c4c4c4;
            box-shadow: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    html {
        font-size: 14px;
    }

    .layout {
        padding-left: 0;
        width: 100%;
        transform: translateX(-0%);
    }
}