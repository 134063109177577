.card-footer {
    background-color: $white_color  !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #dddddd !important;

    .form-select {
        width: auto;
        font-size: 0.8rem;
        background-size: 10px 12px;
    }

    label {
        font-size: 0.84rem;
        color: rgb(123, 128, 154);
        font-weight: 400;
    }

    .pagination {
        justify-content: end !important;
        margin-bottom: 0;
        flex-wrap: wrap;

        .page-item {
            .page-link {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                margin: 0 2px;
                border-radius: 50% !important;
                width: 36px;
                height: 36px;
                font-size: 0.875rem;
                position: relative;
                line-height: 1.25;
                border: 1px solid #dee2e6;
                color: #707377;
                background-color: $white_color;

                span {
                    font-weight: bold;
                    height: 1.25rem;
                }

                &:hover {
                    color: #8898aa;
                    text-decoration: none;
                    background-color: #dee2e6;
                    border-color: #dee2e6;
                }

                &:focus {
                    z-index: 3;
                    outline: 0;
                    box-shadow: none;
                }

                i {
                    font-weight: 900;
                }

                .sr-only {
                    position: absolute;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border: 0;
                }
            }

            &.active .page-link {
                z-index: 3;
                color: $white_color;
                background-color: $main_color;
                border-color: $main_color;
                box-shadow: none;
            }

            &.disabled .page-link {
                color: #c1c5ca;
                pointer-events: none;
                cursor: auto;
                background-color: $white_color;
                border-color: #dee2e6;
            }
        }
    }
}

@media only screen and (max-width: 430px) {
    .card-footer {
        flex-direction: column;
        gap: 8px;
    }
}